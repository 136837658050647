<template>
  <div>
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->

        <div class="d-flex flex-wrap flex-md-nowrap align-items-center">
          <h3 class="font-weight-bolder text-nowrap mb-0">Product</h3>
          <div class="form-col-select ml-2">
            <v-select v-model="perPage" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="perPageOptions"
              :clearable="false" class="per-page-selector d-inline-block" />
            <feather-icon size="18" icon="ChevronDownIcon" />
          </div>
          <div class="search-primary w-100">
            <feather-icon size="16" icon="SearchIcon" />
            <b-form-input v-model="searchQuery" class="d-inline-block mr-1" placeholder="Find Product" />
          </div>
          <b-button variant="primary" class="px-3 mobile-w100" :to="{ name: 'product-create' }">
            <!-- v-if="ability.can('create', 'product_account')" -->
            <span class="text-nowrap"><feather-icon size="14" icon="PlusIcon" /> Product</span>
          </b-button>
        </div>
      </div>

      <b-table ref="refProductListTable" class="position-relative" :items="fetchProducts" responsive
        :fields="tableColumns" primary-key="id" :sort-by.sync="sortBy" show-empty empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc">
        <template #cell(product_categories)="data">
          <b-badge v-for="(category, index) in data.item.product_categories" :key="`category_${index}`"
            style="display: inline">
            {{ category.name }}
          </b-badge>
        </template>

        <template #cell(cover_image_path)="data">
          <el-image style="
              width: 100%;
              aspect-ratio: 1 /1;
              padding: 0;
              max-width: 140px;
              min-width: 70px;
              border-radius: 5px;
            " fit="cover" :src="`${uploadUrl}${data.item.cover_image_path}`"
            :preview-src-list="[`${uploadUrl}${data.item.cover_image_path}`]" :alt="data.item.alt">
          </el-image>
        </template>

        <template #cell(is_active)="data">
          <b-col cols="12">
            <b-form-checkbox :checked="data.item.is_active ? true : false" @change="updateProductStatus(data.item.id)"
              class="custom-control-success" name="check-button" switch inline />
          </b-col>
        </template>

        <template #cell(product_variant)="row">
          <b-button v-model="row.detailsShowing" plain class="btn-primary" @click="row.toggleDetails">
            <span class="vs-checkbox">
              <span class="vs-checkbox--check">
                <i class="vs-icon feather icon-check" />
              </span>
            </span>
            <span class="vs-label">{{
              row.detailsShowing ? "Hide" : "Show"
            }}</span>
          </b-button>
        </template>

        <template #row-details="row">
          <b-card>
            <b-table ref="refProductListTable" class="position-relative" :items="row.item.product_variant" responsive
              :fields="tablevariantColumns" primary-key="id" :sort-by.sync="sortBy" show-empty
              empty-text="No matching records found" :sort-desc.sync="isSortDirDesc">
              <template #cell(variant_image)="row">
                <div style="
                    display: flex;
                    justify-content: start;
                    align-items: center;
                    gap: 10px;
                  ">
                  <!-- <b-col cols="12" md="3" class="mt-2" style="padding: 0"> -->
                  <draggable v-model="row.item.variant_image" @start="drag = true" @end="(event) => {
                    drag = false;
                    updateSortOrderForVariantImage(row.item.variant_image);
                  }">
                    <transition-group>
                      <a v-for="(item, index2) of row.item.variant_image" href="javascript:void(0)" class="drop-item"
                        :key="'docuItem' + item.id + index2" @click="handleDownload(item)">
                        <el-image style="
                        width: 100%;
                        aspect-ratio: 1 /1;
                        padding: 5px;
                        max-width: 140px;
                      " fit="cover" :src="`${uploadUrl}${item.media_path}`" :preview-src-list="row.item.variant_image.map(
                        (x) => uploadUrl + x.media_path
                      )
                        " :alt="item.alt">
                        </el-image>
                      </a>
                    </transition-group>
                  </draggable>
                  <!-- </b-col> -->

                  <!-- <b-col
                    cols="12"
                    md="3"
                    class="mt-2"
                    style="padding: 0"
                    v-for="(image, index) in row.item.variant_image"
                    :key="'media_path' + index"
                  >
                    <el-image
                      style="
                        width: 100%;
                        aspect-ratio: 1 /1;
                        padding: 0;
                        max-width: 140px;
                      "
                      fit="cover"
                      :src="`${uploadUrl}${image.media_path}`"
                      :preview-src-list="
                        row.item.variant_image.map(
                          (x) => uploadUrl + x.media_path
                        )
                      "
                      :alt="image.alt"
                    >
                    </el-image>
                  </b-col> -->
                </div>
              </template>
              <template #cell(is_active)="row">
                <b-col cols="12">
                  <b-form-checkbox :checked="row.item.is_active ? true : false"
                    @change="updateProductVariantStatus(row.item.id)" class="custom-control-success" name="check-button"
                    switch inline />
                </b-col>
              </template>
            </b-table>

            <!-- <b-row
              class="mb-2"
              v-for="(variant, index) in row.item.product_variant"
              :key="`product_variant_${index}`"
            >
              <h4>{{ variant.sku }} - Available Stock : {{ variant.stock }}</h4>
              <b-col md="12" class="mb-1">
                <span v-if="variant && variant.color && variant.color.color">
                  Color:
                  <b-badge :style="{ 'background-color': variant.color.color }">
                    {{ variant.color.color }}
                  </b-badge>
                </span>
                {{
                  variant && variant.size && variant.size.size
                    ? "Size : " + variant.size.size
                    : ""
                }}
                {{
                  variant && variant.type && variant.type.type
                    ? "Type : " + variant.type.type
                    : ""
                }}
              </b-col>
            </b-row> -->

            <b-button size="sm" variant="outline-primary" @click="row.toggleDetails">
              Hide Details
            </b-button>
          </b-card>
        </template>

        <template #cell(avatar)="data">
          <b-avatar :src="data.value" />
        </template>

        <template #cell(status)="data">
          <b-badge :variant="status[1][data.value]">
            {{ status[0][data.value] }}
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown class="action-trigger-btn" :right="$store.state.appConfig.isRTL">
            <template #button-content>
              <feather-icon icon="EyeIcon" size="16" class="align-middle text-body" />
            </template>
            <!-- v-if="ability.can('view', 'product_account')" -->
            <b-dropdown-item :to="{ name: 'product-detail', params: { id: data.item.id } }">
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">Details</span>
            </b-dropdown-item>

            <!-- v-if="ability.can('update', 'product_account')" -->
            <b-dropdown-item :to="{ name: 'product-edit', params: { id: data.item.id } }">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>

            <!-- <b-dropdown-item v-if="data.item.is_company && ability.can('update', 'product_account')" >
              <feather-icon icon="MailIcon" />
              <span class="align-middle ml-50">Select Birthday Email</span>
            </b-dropdown-item> -->
          </b-dropdown>
        </template>
      </b-table>

      <div class="mx-2 mb-2">
        <b-row>
          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
            <b-pagination v-model="currentPage" :total-rows="totalProducts" :per-page="perPage" first-number last-number
              class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  VBModal,
  BFormGroup,
  BForm,
  BOverlay,
  BFormCheckbox,
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import { ref, onUnmounted } from "@vue/composition-api";
import useProductsList from "./useProductsList";
import productStoreModule from "../productStoreModule";
import AppTimeline from "@core/components/app-timeline/AppTimeline.vue";
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue";
import Ripple from "vue-ripple-directive";
import draggable from "vuedraggable";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    AppTimeline,
    AppTimelineItem,
    VBModal,
    BFormGroup,
    BForm,
    BOverlay,
    BFormCheckbox,
    draggable,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      productInfo: { id: 0, password: "", password_confirmation: "" },
      show: false,
    };
  },
  methods: {
    updateSortOrderForVariantImage(images) {
      this.loading = true;
      store.dispatch("app-product/updateImageOrder", { images: images })
        .then((response) => {
          this.loading = false;
          this.$swal({
            text: response.data.message,
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            showClass: {
              popup: "animate__animated animate__bounceIn",
            },
            buttonsStyling: false,
          });
          this.refetchData();
        })
        .catch((error) => {
          this.loading = false;
          this.$swal({
            text: JSON.stringify(error.response.data.message),
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            showClass: {
              popup: "animate__animated animate__bounceIn",
            },
            buttonsStyling: false,
          });
        });
    },
    updateProductStatus(id) {
      this.loading = true;
      store
        .dispatch("app-product/updateProductStatus", id)
        .then((response) => {
          this.loading = false;
          this.$swal({
            text: response.data.message,
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            showClass: {
              popup: "animate__animated animate__bounceIn",
            },
            buttonsStyling: false,
          });
          this.refetchData();
        })
        .catch((error) => {
          this.loading = false;
          this.$swal({
            text: JSON.stringify(error.response.data.message),
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            showClass: {
              popup: "animate__animated animate__bounceIn",
            },
            buttonsStyling: false,
          });
        });
    },
    updateProductVariantStatus(id) {
      this.loading = true;
      store
        .dispatch("app-product/updateProductVariantStatus", id)
        .then((response) => {
          this.loading = false;
          this.$swal({
            text: response.data.message,
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            showClass: {
              popup: "animate__animated animate__bounceIn",
            },
            buttonsStyling: false,
          });
          this.refetchData();
        })
        .catch((error) => {
          this.loading = false;
          this.$swal({
            text: JSON.stringify(error.response.data.message),
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            showClass: {
              popup: "animate__animated animate__bounceIn",
            },
            buttonsStyling: false,
          });
        });
    },

    onBtnExport() {
      console.log("product export");
      store.dispatch("app-product/fetchProductsExport").then((response) => {
        // create file link in browser's memory
        const href = URL.createObjectURL(response.data);
        // create "a" HTLM element with href to file & click
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute("download", `Products Export List`);
        document.body.appendChild(link);
        link.click();
        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      });
    },
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = "app-product";
    const uploadUrl = ref(process.env.VUE_APP_IMAGE_URL);

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      store.registerModule(USER_APP_STORE_MODULE_NAME, productStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME))
        store.unregisterModule(USER_APP_STORE_MODULE_NAME);
    });

    const {
      fetchProducts,
      tableColumns,
      tablevariantColumns,
      perPage,
      currentPage,
      totalProducts,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refProductListTable,
      refetchData,
      ability,
    } = useProductsList();

    return {
      // Sidebar
      fetchProducts,
      tableColumns,
      tablevariantColumns,
      perPage,
      currentPage,
      totalProducts,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refProductListTable,
      refetchData,
      ability,
      uploadUrl,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.cursor {
  cursor: pointer;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>

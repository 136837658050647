import { ref, watch, computed } from "@vue/composition-api";
import store from "@/store";
import { title } from "@core/utils/filter";

// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import ability from "@/libs/acl/ability";

export default function useProductsList() {
  // Use toast
  const toast = useToast();

  const refProductListTable = ref(null);

  // Table Handlers
  const tableColumns = [
    // { key: "id", label: "ID", sortable: true },
    { key: "actions" },
    {
      key: "name",
      stickyColumn: true,
      label: "Product Name",
      sortable: true,
    },
    { key: "is_active", label: "Active" },
    { key: "cover_image_path", label: "Cover Image" },
    { key: "price", label: "Product Price" },
    { key: "product_categories", label: "Product Categories" },
    { key: "product_variant", label: "Product Variants" },
  ];

  // Table Handlers
  const tablevariantColumns = [
    // { key: "id", label: "ID" },
    { key: "sku", label: "SKU" },
    { key: "color.color", label: "Color" },
    { key: "size.size", label: "Size" },
    { key: "type.type", label: "Type" },
    { key: "stock", label: "Stock" },
    { key: "variant_image", label: "Variant Images" },
    { key: "is_active", label: "Active" },
  ];
  const perPage = ref(10);
  const totalProducts = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];
  const searchQuery = ref("");
  const sortBy = ref("id");
  const isSortDirDesc = ref(true);

  const dataMeta = computed(() => {
    const localItemsCount = refProductListTable.value
      ? refProductListTable.value.localItems.length
      : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalProducts.value,
    };
  });

  const refetchData = () => {
    refProductListTable.value.refresh();
  };

  watch([currentPage, perPage, searchQuery], () => {
    refetchData();
  });

  const fetchProducts = (ctx, callback) => {
    store
      .dispatch("app-product/fetchProducts", {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
      })
      .then((response) => {
        console.log(response);
        const { products, total } = response.data;
        callback(products);
        totalProducts.value = total;
      })
      .catch((err) => {
        console.log(err);
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching product list",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  };

  return {
    fetchProducts,
    tableColumns,
    tablevariantColumns,
    perPage,
    currentPage,
    totalProducts,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refProductListTable,
    ability,
    refetchData,
  };
}
